import { GeometryCollection } from "./geoJSON";


export type PostType = 'CATCH' | 'SNAPSHOT' | 'VIDEO' | 'UNKNOWN';
export type ItemPrivacy = 'PRIVATE' | 'PUBLIC';
export type CatchGender = 'MALE' | 'FEMALE' | 'UNKNOWN';
export type UserGender = CatchGender;
export type UserRelationshipIncomingStatus = 'followed_by' | 'requested_by' | 'blocked_by_you' | 'none';
export type UserRelationshipOutgoingStatus = 'following' | 'requested' | 'blocked' | 'none';
export type SpotFlag = 'NONE' | 'GREEN' | 'RED' | 'YELLOW';
export type NotificationStatus = 'EVERYONE' | 'FOLLOWING' | 'OFF';
export type TripUserType = 'ADMIN' | 'MEMBER' | 'OBSERVER';
export type UnitLength = 'CM' | 'INCHES';
export type UnitWeight = 'KG' | 'LBS';
export type UnitTemperature = 'CELSIUS' | 'FAHRENHEIT';
export type SystemWaterwayType = 'RIVER' | 'LAKE';

export type OAuthClientRoleTypes = 'PUBLIC' | 'RESTRICTED' | 'BUSINESS';

export type RelationshipType = 'FOLLOWING' | 'REQUESTED' | 'BLOCKED' | 'NONE';

export type AuthenticationProvider = 'FACEBOOK' | 'ANGLING_IQ' | 'APPLE' | 'GOOGLE';

export type CustomerType = 'PERSON' | 'COMPANY' | null;

type BookingOfferStatus = 'BOOKING' | 'OFFER';

export enum BusinessServiceTypeEnum {
	LOGBOOK = 1,
	BOOKINGS = 2
}

export enum BaitType {
	Flies = 1,
	Lures = 2,
	NaturalBaits = 3,
	Worm = 4
}

export interface AIQModel {
	id: number;
}

export interface ICommentsDataObject {
	data: IComment[];
	count: number;
}

export interface ILikesDataObject {
	data: IUser[];
	count: number;
}

export interface IImagesDataObjectWithoutHigh {
	low: IImage;
	standard: IImage;
}

export interface IImagesDataObject extends IImagesDataObjectWithoutHigh {
	high: IImage;
}

export interface ITimeSeriesObject {
	date: Date;
	count: number;
	count_before_noon?: number;
	count_after_noon?: number;
	species?: ISpecies[];
}

export type IVideoObject = IImage;

export interface ICommonPostData {
	id: number;
	created?: string;
	updated?: string;
	title?: string;
	privacy: ItemPrivacy;
	user: IUser;
	waterway?: IWaterway;
	trip?: ITrip;
	liked: boolean;
	timestamp: string;
	comments?: ICommentsDataObject | null;
	likes?: ILikesDataObject | null;
	coordinate?: ICoordinate;
	images: IImagesDataObject;
}

export interface IMeasurementsDataObject {
	length?: number;
	weight?: number;
	girth?: number;
}

export interface IAccessToken {
	id?: number;
	expiration: string;
	token: string;
	valid: boolean;
	user: IUser;
}

export interface IRefreshToken {
	id: number;
	token: string;
	valid?: boolean;
	user: IUser;
}

export interface ILogbookLogLink {
	id: number;
	token: string;
	expires?: string;
	fisherman_name?: string;
	name: string;
	catch_count: number;
	is_valid: boolean;
}

export interface IAd {
	id: number;
	created?: string;
	updated?: string;
	expiration: string;
	show_profile: boolean;
	disabled: boolean;
	external_link: string;
	ad_type: IAdType;
	adExternalLinkType: IAdExternalLinkType;
	post?: IPost;
	bait?: IBait;
	staticAd?: IStaticAd;
}

export interface IAdExternalLinkType {
	id: number;
	name: string;
}

export interface IAdType {
	id: number;
	name: string;
}

export interface IStaticAd {
	id: number;
	title: string;
	created: string;
	updated: string;
	user: IUser;
	image: IImage;
}

export interface IActivity {
	id: number;
	created?: string;
	updated?: string;
	object_id?: number;
	source_id: number;
	alert: string;
	image_url?: string;
	type_id: number;
	read: boolean;
	sender: Pick<IUser, 'id' | 'username' | 'private'>;
}

export interface IAlbum {
	id: number;
	created?: string;
	updated?: string;
	name: string;
	description: string;
	privacy: ItemPrivacy;
	post_count: number;
	user: IUser;
	posts: IPost[];
}

export interface IBait {
	id: number;
	created: string;
	updated?: string;
	name: string;
	privacy: ItemPrivacy;
	description?: string;
	images?: IImagesDataObject;
	system: boolean;
	has_bait?: boolean;
	is_owner?: boolean;
	user?: IUser;
	catch_count?: number;
	user_count?: boolean;
	liked?: boolean;
	comments: ICommentsDataObject | null;
	likes: ILikesDataObject | null;
	subtype?: IBaitSubtype;
}

export interface IBaitSubtype {
	id: number;
	created?: string;
	updated?: string;
	name: string;
	type: IBaitType;
}

export interface IBaitType {
	id: number;
	created?: string;
	updated?: string;
	name: string;
	sub_types: IBaitSubtype[];
}

export interface IBaitSize {
	id: number;
	created?: string;
	updated?: string;
	value: string;
}

export interface ICatchWeatherObject {
	type: string; // TODO: Add the listings as a type
	description: string;
	temperature?: number;
	wind_speed: number;
	wind_direction?: number;
	humidity?: number;
	pressure?: number;
	moon_phase?: number;
}

export interface ICatch extends Omit<ICommonPostData, 'images'> {
	fulton?: number;
	measurements?: IMeasurementsDataObject;
	notes?: string;
	gender: CatchGender;
	logbook: boolean;
	sea_lice?: boolean;
	species?: ISpecies;
	guide_id?: number;
	tag?: IFishTag;
	spot?: ISpot;
	bait?: IBait;
	guide?: IUser;
	baitSize?: IBaitSize;
	catch_and_release?: boolean;
	bait_type?: IBaitType;
	catch_owner_name?: string;
	weather?: ICatchWeatherObject;
	is_weight_user_inputted?: boolean;
	season?: ISeason;
	season_part?: ISeasonPart;
	images?: IImagesDataObject;
}

export interface ICoordinate {
	latitude: number;
	longitude: number;
}

export interface ICatchRanking {
	catch: ICatch;
	ranking: number;
}

export interface IEmailSetting {
	id: number;
	name: string;
	description?: string;
	allowed: boolean;
}

export interface IUser {
	id: number;
	created?: string;
	updated?: string;
	username: string;
	full_name?: string;
	email: string;
	profile_picture?: string;
	cover_photo?: string;
	birthday?: string;
	bio?: string;
	verified?: boolean;
	private: boolean;
	gender?: UserGender;
	has_aiq_login?: boolean;
	has_facebook_login?: boolean;
	has_google_login?: boolean;
	has_apple_login?: boolean;
	outgoing_relationship?: UserRelationshipOutgoingStatus;
	incoming_relationship?: UserRelationshipIncomingStatus;
	post_notifications?: boolean;
	counts?: IUserCounts;
	country?: ICountry;
	units?: IUserUnits;
	fishing_methods?: IFishingMethod[];
}

export interface ISpecies {
	id: number;
	created?: string;
	updated?: string;
	name: string;
	is_name?: string;
	catch_count?: number;
	catch_and_release_count?: number;
	catch_and_release_ratio?: number;
	avg_weight?: number;
	avg_length?: number;
	catches?: ICatch[];
	images?: IImagesDataObject;
	compare?: {
		season: ISeason;
		catch_count: number;
		avg_weight: number;
		percentage_change: number;
		avg_length: number;
	}
}

export interface IFishTag {
	id: number;
	created: string;
	tag: string;
	catch_count: number;
}

export interface ISpot {
	id: number;
	created?: string;
	updated?: string;
	name: string;
	description?: string;
	flag?: SpotFlag;
	number?: string;
	privacy?: ItemPrivacy;
	catch_count?: number;
	distance?: number;
	coordinate: ICoordinate;
	user: IUser;
	images?: IImagesDataObjectWithoutHigh;
}

export interface IComment {
	id: number;
	created?: string;
	updated?: string;
	text: string;
	user: IUser;
}

export interface IImage {
	url: string;
	width: number;
	height: number;
}

export interface IPost {
	id: number;
	created: string;
	updated?: string;
	type: PostType;
	data: ICommonPostData;
}

export interface IUserCounts {
	following: number;
	followers: number;
	catches: number;
	snapshots: number;
	moments: number;
	trips: number;
	waterways: number;
	baits: number;
}

export interface ICountry {
	id: number;
	name: string;
	code: string;
	dial_code?: string;
}

export interface IFishingMethod {
	id: number;
	name: string;
}

export interface IUserCatches {
	user: IUser;
	catches: ICatch[];
}

export interface IFacebookUser {
	facebook_id: number;
	email: string;
	full_name?: string;
	gender?: string;
}

// Legacy
export type ISnapshot = IMoment;

export type IPhoto = ICommonPostData;
export type IMoment = ICommonPostData;

export interface IVideo extends ICommonPostData {
	videos: {
		low: IVideoObject;
		standard: IVideoObject;
	};
}

export interface IUserUnits {
	length: UnitLength;
	weight: UnitWeight;
	temperature: UnitTemperature;
}

export interface ITag {
	id: number;
	name: string;
	post_count: number;
}

export interface INotificationSetting {
	id: number;
	name: string;
	pretty_name: string;
	description: string;
	allowed_statuses: NotificationStatus[];
	status: NotificationStatus;
}

export interface ITrip {
	id: number;
	created?: string;
	updated?: string;
	name: string;
	description?: string;
	privacy: ItemPrivacy;
	catch_count?: number;
	snapshot_count?: number;
	member_count?: number;
	cover_photo?: string;
	member_type?: TripUserType;
	start?: string;
	end?: string;
	members?: ITripUser[];
	system_waterway?: ISystemWaterway;
	largest_catch?: ICatch;
}

export interface ITripUser {
	id: number;
	created?: string;
	updated?: string;
	user: IUser;
	verified: boolean;
	type: TripUserType;
	catch_count: number;
}

export interface IWeather {
	time?: string;
	type: string;
	description: string;
	temperature: number;
	wind_speed: number;
	sunrise_time?: string;
	sunset_time?: string;
	wind_direction?: number;
	humidity: number;
	pressure: number;
	moon_phase: number;
}

export interface IWaterway {
	id: number;
	created?: string;
	updated?: string;
	name: string;
	description?: string;
	privacy: ItemPrivacy;
	catch_count: number;
	snapshot_count: number;
	linked_to?: ISystemWaterway;
	spots: ISpot[];
	user: IUser;
}

export interface ISystemWaterway {
	id: number;
	created?: string;
	updated?: string;
	name: string;
	season_start_date?: string;
	favourite?: boolean;
	season_end_date?: string;
	catch_count?: number;
	species_count?: number;
	moment_count?: number;
	spot_count?: number;
	follower_count?: number;
	center_coordinate?: ICoordinate;
	allow_remote_logging?: boolean;
	has_public_logbook: boolean;
	permit_sales_enabled: boolean;
	administrative_area: string;
	logbook_notifications?: boolean;
	is_linked?: boolean;
	bbox?: {
		sw: ICoordinate;
		ne: ICoordinate;
	};
	size?: number;
	following?: boolean;
	is_editor?: boolean;
	coordinates?: ICoordinate[] | null;
	country?: ICountry;
	type?: SystemWaterwayType;
	permits?: IPermit[];
}

export interface ILogbook extends ISystemWaterway { }

export interface ISystemWaterwayUser {
	user: IUser;
	catch_count: number;
	catches?: ICatch[];
}

export interface IBusinessAccount {
	id: number;
	ssn: string;
	company_name: string;
	postal_code: string;
	address_line_1: string;
	address_line_2?: string;
	city: string;
	country: ICountry;
	has_rapyd_access_key?: boolean;
	has_rapyd_secret_key?: boolean;
}

export interface IBusinessAccountServiceUser {
	id: number;
	verified: boolean;
	admin: boolean;
	business_user: IBusinessUser;
}

export interface IBusinessAccountService {
	id: number;
	created: string;
	active: boolean;
	business_account: IBusinessAccount;
	business_service_type: IBusinessServiceType;
	system_waterway: ISystemWaterway;
	service_users: IBusinessAccountServiceUser[];
	service_user?: IBusinessAccountServiceUser;
}

export interface IBusinessServiceType {
	id: number;
	name: string;
	display_name: string;
}

export interface IBusinessUser {
	id: number;
	email: string;
	first_name: string;
	last_name: string;
	backing_user?: IUser;
	business_account?: IBusinessAccount;
	services?: IBusinessAccountService[];
	require_password_change?: boolean;
	email_verified?: boolean;
}

export interface IPermitAngler {
	id: number;
	customer?: ICustomer;
	name?: string;
}

export interface IPermit {
	id?: number;
	permit_ids?: number[];
	start_date: string;
	end_date: string;
	type_id: number;
	unit_price: number | null;
	total_price: number | null;
	starts_in_pm: boolean;
	total_quantity: number;
	available_quantity: number;
	batch_quantity: number;
	marketplace_quantity?: number;
	available_marketplace_quantity?: number;
	day_count: number;
	currency: ICurrency;
	bookings?: IBookingOffer[];
	offers?: IBookingOffer[];
	system_waterway?: ISystemWaterway;
	anglers?: IPermitAngler[];
	guide?: IGuide;
	guide_required?: boolean;
	// season_part?: IPermitSeasonPart;
}

export type IPermitRodInformation = Required<Pick<IPermit, 'id' | 'anglers' | 'guide' | 'guide_required'>>;

export interface IPermitWithBatch extends Omit<IPermit, 'id'> {
	id?: number;
	permit_batch?: {
		id: number;
		quantity: number;
	}
}

export interface IPermitBookingSettings {
	id: number;
	minimum_days: number;
	currency_id: number;
}

export interface ISeasonPart {
	id: number;
	start_date: string;
	end_date: string;
	name: string;
	rod_count?: number;
}

export interface IPermitSeasonPart extends ISeasonPart {
	batch_count?: number;
	base_unit_price?: number;
}

export interface ISeason {
	id: number;
	start_date: string;
	end_date: string;
	name: string;
	parts: ISeasonPart[];
}

export interface IPermitSeason extends ISeason {
	parts: IPermitSeasonPart[];
}

export interface ILogbookSeason extends ISeason {
	parts: Omit<ISeasonPart, 'batch_count'>[];
}

export interface ILogbookSettings {
	id: number;
	default_season_start_date: Date;
	default_season_end_date: Date;
	max_batch_log: number;
	allow_batch_log: boolean;
	allow_remote_logging: boolean;
	batch_log_species: ISpecies[];
	spot_required: boolean;
	default_species?: ISpecies;
	bait_types: IBaitType[];
}

export interface ILogbookSettings {
	id: number;
	default_season_start_date: Date;
	default_season_end_date: Date;
	max_batch_log: number;
	allow_batch_log: boolean;
	allow_remote_logging: boolean;
	batch_log_species: ISpecies[];
}

export interface ICatchGroup {
	id: number;
	created: string;
	catch_count: number;
	user: IUser;
	species: ISpecies;
	system_waterway: ISystemWaterway;
	catches: ICatch[];
}

export type TideDirection = 'UP' | 'DOWN';
export type TideItemExtremeType = 'HIGH' | 'LOW';

export interface ITideItem {
	height: number;
	dt: number;
	date: string;
	type: TideItemExtremeType;
}

export interface ITide {
	direction?: TideDirection;
	currentTide: ITideItem;
	heights: ITideItem[];
	extremes: ITideItem[];
}

export interface ICustomer {
	id: number;
	ssn?: string;
	legal_name: string;
	first_name?: string;
	last_name?: string;
	verified: boolean;
	country?: ICountry;
	user?: IUser;
	email?: string;
	phone_number?: string;
	shipping_address?: IAddress;
	billing_address?: IAddress;
	type?: CustomerType;
}

export interface IGuide {
	id: number;
	ssn?: string;
	first_name: string;
	last_name: string;
	user?: IUser;
	verified: boolean;
	country?: ICountry;
	email?: string;
	phone_number?: string;
	address?: IAddress;
}

export interface IAddress {
	id?: number;
	address_line_1: string;
	address_line_2?: string;
	city: string;
	postal_code: string;
	country?: ICountry;
}

export interface ISystemWaterwayBeat {
	id: number;
	name: string;
	description: string;
	geom: GeometryCollection | null;
}

export interface IBookingOffer {
	id: number;
	created: string;
	status: BookingOfferStatus;
	permit_quantity: number;
	total_price?: number;
	total_price_with_vat?: number;
	vat_amount?: number;
	// closed?: boolean;
	// expires?: string;
	customer: ICustomer;
	permits: IBookingOfferPermit[];
}

export interface ICurrency {
	code: string;
}

export interface IBookingOfferPermit {
	id: number;
	permit: IPermit;
	billing_item: IBillingItem;
}

export interface IVat {
	id?: number;
	name: string;
	rate: number;
}

export interface IBillingItem {
	line_item_price: number;
	line_item_quantity: number;
	quantity: number;
	total_price: number;
	total_price_with_vat: number;
	vat_amount: number;
	unit_price: number;
	vat?: IVat;
	currency: ICurrency;
}

interface SummaryObject<T> {
	latest: T[];
	count: number;
}

export type ICatchesSummary = SummaryObject<ICatch>;
export type IMomentsSummary = SummaryObject<IPost>;
export type ITripsSummary = SummaryObject<ITrip>;